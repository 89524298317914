<template>
    <div>
        <aw-card :title="'数据列表（' + title_name + '）'">
            <template #table_extras>
                <el-button v-if="powerlimits.includes('cdgl_tjyjcd')" type="primary" @click="editEvent(null, 'add')">添加一级菜单</el-button>
            </template>
            <template #content>
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    :header-cell-style="{ background: '#F5F7FA', 'font-weight': 'bold', color: '#666' }"
                    row-key="id"
                    border
                    default-expand-all
                >
                    <el-table-column prop="permission_name" label="导航名称【排序】">
                        <template #default="scope">
                            <span>{{ scope.row.permission_name }} 【{{ scope.row.sort }}】</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" :width="80" />
                    <el-table-column prop="module" label="后端模块" :width="90" />
                    <el-table-column prop="permission_mark" label="后端路由" />
                    <el-table-column prop="route" label="后端接口" />
                    <el-table-column prop="icon" label="前端icon" :width="90" />
                    <el-table-column prop="component" label="前端路由" />
                    <el-table-column prop="type" label="类型" :width="80">
                        <template #default="scope">
                            <el-tag :type="scope.row.type == 1 ? 'success' : 'warning'">{{ menu_options.menu_type[scope.row.type] }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hidden" label="隐藏与否" :width="90">
                        <template #default="scope">
                            <el-tag :type="scope.row.hidden == 1 ? '' : 'info'">{{ menu_options.menu_hidden[scope.row.hidden] }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" :width="260" prop="myoperation" id="operation">
                        <template #default="scope">
                            <el-button v-if="scope.row.type != 2 && powerlimits.includes('cdgl_tjzcd')" type="primary" size="small" @click="editEvent(scope.row, 'add_row_child')"
                                >添加子菜单</el-button
                            >
                            <el-button v-if="powerlimits.includes('cdgl_bj')" type="primary" size="small" @click="editEvent(scope.row, 'edit_row')">编辑</el-button>
                            <el-button v-if="powerlimits.includes('cdgl_sc')" type="danger" size="small" @click="delEvent(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </aw-card>
        <el-drawer
            :model-value="drawer.show"
            :title="drawer.title"
            :z-index="1101"
            append-to-body
            @close="drawer.close"
            :direction="drawer.direction || 'rtl'"
            :size="drawer.size || '70%'"
            style="overflow-y: scroll"
        >
            <el-scrollbar max-height="100%">
                <MenuAddOrEdit ref="menuAddOrEditRef" :menu_info="menu_info" :menu_flag="menu_flag" @close-drawer="drawer.close" />
            </el-scrollbar>
        </el-drawer>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict, tool } from '@/assets/js'
import { reactive, ref } from 'vue'
import MenuAddOrEdit from './common/MenuAddOrEdit.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const title_name = ref(route.meta.name.split(' | ')[0])
// 当前页面操作权限集
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.role, 'component'))

const menu_options = ref({
    menu_type: dict.menu.menu_type,
    menu_hidden: dict.menu.menu_hidden
})
const tableData = ref([])
const loading = ref(false)
const getAxiosEvent = async (name) => {
    if (powerlimits.includes('cdgl_lb')) {
        loading.value = true
        let result = await http.get('/admin/permissions', { params: { permission_name: name } })
        tableData.value = result
        loading.value = false
    } else {
        tableData.value = []
    }
}

const menu_info = ref({})
const add_edit_flag = ref({ add: '添加一级菜单', edit_row: '编辑', add_row_child: '添加子菜单' })
const menu_flag = ref('')
const editEvent = async (row, flag) => {
    drawer.value.show = true
    drawer.value.title = add_edit_flag.value[flag]
    menu_flag.value = flag

    if (flag == 'add') {
        // 添加一级菜单
        menu_info.value = {
            permission_name: '',
            permission_mark: '',
            restful: '0',
            route: '',
            module: '',
            icon: '',
            component: '',
            redirect: '',
            hidden: '1',
            sort: '',
            parent_id: '0',
            type: '1'
        }
    } else if (flag == 'edit_row') {
        // 编辑当前行菜单
        let result = await http.get(`/admin/permissions/${row.id}/edit`)
        menu_info.value = {
            id: result.id,
            permission_name: result.permission_name,
            permission_mark: result.permission_mark,
            restful: result.restful || '0',
            route: result.route,
            module: result.module || 'admin',
            icon: result.icon,
            component: result.component,
            redirect: result.redirect,
            hidden: result.hidden.toString(),
            sort: result.sort,
            parent_id: result.parent_id,
            type: result.type.toString()
        }
    } else if (flag == 'add_row_child') {
        // 添加子菜单row
        menu_info.value = {
            permission_name: row.permission_name,
            permission_mark: row.permission_mark,
            restful: '0',
            route: row.route,
            module: row.module || 'admin',
            icon: row.icon,
            component: row.component,
            redirect: row.redirect,
            hidden: row.hidden.toString(),
            sort: row.sort,
            parent_id: row.id,
            type: row.type.toString()
        }
    }
}

const delEvent = async (row) => {
    util.confirm({
        title: '删除',
        action: async () => {
            await http.delete(`/admin/permissions/${row.id}`)
            getAxiosEvent()
        }
    })
}

const menuAddOrEditRef = ref(null)
const drawer = ref({
    show: false,
    title: '',
    close: () => {
        drawer.value.show = false
        menuAddOrEditRef.value.resetFieldsEvent()
        getAxiosEvent()
    }
})

getAxiosEvent()
</script>
<style scoped></style>
